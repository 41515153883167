import { Link } from "gatsby";
import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Trans } from "react-i18next";

const CTA = () => (
  <>
    {/* <!-- CTA section --> */}
    <div className="cta-section-2 dark-mode-texts bg-blackish-blue ">
      <Container>
        <div className="cta-wrapper pt-14 pb-14 py-lg-19 border-bottom">
          <Row className="align-items-center justify-content-center">
            <Col lg="6" md="10">
              <div className="cta-text section-title">
                <h2 className="title gr-text-5 mb-7 text-white">
                  <Trans>provenexpert.cta-title</Trans>
                </h2>
                <p className="gr-text-8 gr-color-white-opacity-7 mb-8 mb-lg-0">
                  <Trans>provenexpert.cta-text</Trans>
                </p>
              </div>
            </Col>
            <Col lg="6" md="10" className="offset-lg-0">
              <div className="hero-btns d-flex flex-column flex-md-row justify-content-md-center mt-11">
                <Button
                  className="btn btn-primary with-icon gr-hover-y mb-6 mb-md-0 mr-md-7"
                  href="https://app.trustify.ch/#/auth/register"
                >
                  <Trans>provenexpert.cta-button-1</Trans>
                  <i className="fas fa-arrow-right gr-text-11"></i>
                </Button>
                <Link to="/#" className="btn btn-white gr-hover-y">
                  <Trans>provenexpert.cta-button-2</Trans>
                </Link>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  </>
);

export default CTA;
